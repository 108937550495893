<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron novedades"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :toolbar-options="toolbarOptions"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idlparte_novedad"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <lparte-novedad-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="canAddNovedad"
      @click="clickAdd"
    />
    <lparte-novedad-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LparteNovedadListData'
import LparteNovedadListItem from './components/LparteNovedadListItem'
import LparteNovedadListLegend from './components/LparteNovedadListLegend'
import { PARTE_NOVEDAD } from '@/utils/consts'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesLparteNovedadList'

export default {
  components: {
    LparteNovedadListItem,
    LparteNovedadListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    addRoute: {
      type: String,
      default: ''
    },
    viewRoute: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
      pageStoreName,
      PARTE_NOVEDAD,
      // parteNovedad: {},
      canAddNovedad: false,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdvigilante: get('usuario/idvigilante'),
    presenciaIdpuestoServicio: get('presencia/idpuestoServicio'),
    presenciaIdpuestoServicioServicio: get('presencia/idpuestoServicioServicio'),
    presenciaIdempresa: get('presencia/idempresa'),
  },
  async created () {
    if (this.routeParams.refreshList) {
      // Esto es como darle al botón refresh
      await this.dispatchStore('resetList')
      await this.dispatchStore('resetScroll')
      await this.loadItems()
    }
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.routeParams.idparte_novedad) {
        this.title = `Novedades parte Nº${this.routeParams.idparte_novedad}`
      } else if (this.routeParams.idpuesto_servicio_servicio) {
        this.title = `Novedades del servicio`
      } else if (this.routeParams.idpuesto_servicio) {
        this.title = `Novedades del puesto`
      }
      // Comparación abstracta porque el routeparams es string y en el store es number
      if(this.routeParams.idpuesto_servicio_servicio) {
        this.canAddNovedad = this.routeParams.idpuesto_servicio_servicio == this.presenciaIdpuestoServicioServicio
      } else if (this.routeParams.idpuesto_servicio) {
        this.canAddNovedad = this.routeParams.idpuesto_servicio == this.presenciaIdpuestoServicio
      } else if (this.routeParams.idparte_novedad) {
        const [dataset] = await Data.selectParteNovedad(this, this.routeParams.idparte_novedad)
        this.canAddNovedad = dataset.idpuesto_servicio === this.presenciaIdpuestoServicio && dataset.idempresa === this.presenciaIdempresa
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLparteNovedad(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_novedad,
          this.routeParams.idpuesto_servicio,
          this.routeParams.idpuesto_servicio_servicio,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectLparteNovedadRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idlparte_novedad')
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: this.viewRoute,
        params: {
          idlparte_novedad: item.data.idlparte_novedad,
          idparte_novedad: item.data.idparte_novedad,
        },
      })
    },
    async clickAdd () {
      // sin remember porque agrega un registro
      let idparteNovedad = this.routeParams.idparte_novedad
      if (!idparteNovedad) {
        const parteNovedad = await this.$online.parteNovedad.getOrCreateParteNovedad(this.presenciaIdpuestoServicio, this.presenciaIdempresa)
        idparteNovedad = parteNovedad?.idparte_novedad
      }
      if (idparteNovedad) {
        this.$appRouter.push({
          name: this.addRoute,
          params: {
            idparte_novedad: idparteNovedad
          },
        })
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    async openFilter () {
      if (
        this.filter.puestoServicio.items.length === 0 &&
        this.filter.puestoServicioServicio.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this, this.usuarioIdvigilante)
        await this.setStoreProperty('filter@puestoServicio.items', resp.data.selectPuestoServicio.result.dataset)
        await this.setStoreProperty('filter@puestoServicioServicio.items', resp.data.selectPuestoServicioServicio.result.dataset)
      }
    },
  }
}
</script>
