import { toDate, endOfDay } from '@/utils/date'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLparteNovedad (Vue, filter, search, sorter, page, idparteNovedad, idpuestoServicio, idpuestoServicioServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (idparteNovedad) {
      apiFilter.addExact('idparte_novedad', idparteNovedad)
    }
    if (idpuestoServicio) {
      apiFilter.addExact('idpuesto_servicio', idpuestoServicio)
    }
    if (idpuestoServicioServicio) {
      apiFilter.addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
    }
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.parteNovedad.value && filter.parteNovedad.value.length > 0) {
      apiFilter.addIn(filter.parteNovedad.field, filter.parteNovedad.value)
    }
    if (filter.puestoServicioServicio.value && filter.puestoServicioServicio.value.length > 0) {
      apiFilter.addIn(filter.puestoServicioServicio.field, filter.puestoServicioServicio.value)
    }
    if (filter.puestoServicio.value && filter.puestoServicio.value.length > 0) {
      apiFilter.addIn(filter.puestoServicio.field, filter.puestoServicio.value)
    }
    const resp = await Vue.$api.call('lparteNovedad.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLparteNovedadRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idlparte_novedad', pks)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectParteNovedad (Vue, idparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_novedad', idparteNovedad)
    const resp = await Vue.$api.call('parteNovedad.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectFilterLookups (Vue, idvigilante) {
    let apiCalls = []
    const apiFilterVigilante = new APIFilter()
    apiFilterVigilante
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectPuestoServicio', method: 'puestoServicio.select', params: { filter: apiFilterVigilante } })
    apiCalls.push({ name: 'selectPuestoServicioServicio', method: 'puestoServicioServicio.select', params: { filter: apiFilterVigilante } })
    return await Vue.$api.batchCall(apiCalls)
  }
}
